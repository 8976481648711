<template>
  <div class="scheme-contain">
  <div class="data-contain">
    <div class="card-wrapper">
      <div class="train-search">
        <div class="train-type">
          <Select v-model="trainType" style="width:200px">
						<Option value="0">靶心率训练</Option>
						<Option value="1">蓝牙哑铃</Option>
					</Select>
        </div>
        <div class="train-date">
          <Date-picker format="yyyy-MM-dd" v-model="time" type="daterange" placeholder="选择日期" @on-change="handleChangeDate" style="width:200px;"></Date-picker>
        </div>
        <div class="tab-contain">
          <router-link class="static-button" :to="{name: 'Train'}">统计显示</router-link>
          <router-link :to="{name: 'TrainTable'}">数据显示</router-link>
          <router-link class="chart-button" :to="{name: 'TrainReport'}">训练报表</router-link>
        </div>
      </div>
      <Row :gutter="40">
        <Col :lg="{span: 8}" :md="{span: 12}">
          <div class="sports-card bg1">
            <Row>
              <Col :lg="{span: 12, offset: 6}">
                <Row type="flex" justify="center" align="middle">
                  <Col span="8"><img src="@/assets/img/icon-12.png" alt="" width="40px" height="40px"></Col>
                  <Col span="16">
                    <p><em class="font_30">{{sum_count}}</em>次</p>
                    <h2>总训练次数</h2>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
        <Col :lg="{span: 8}" :md="{span: 12}">
          <div class="sports-card bg2">
            <Row>
              <Col :lg="{span: 12, offset: 6}">
                <Row type="flex" justify="center" align="middle">
                  <Col span="8"><img src="@/assets/img/icon_11.png" alt="" width="40px" height="40px"></Col>
                  <Col span="16">
                    <p><em class="font_30">{{sum_time}}</em>min</p>
                    <h2>总训练时长</h2>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
        <Col :lg="{span: 8}" :md="{span: 12}">
          <div class="sports-card bg3">
            <Row>
              <Col :lg="{span: 12, offset: 6}">
                <Row type="flex" justify="center" align="middle">
                  <Col span="8"><img src="@/assets/img/icon-13.png" alt="" width="40px" height="40px"></Col>
                  <Col span="16">
                    <p><em class="font_30">{{sum_total}}</em>kcal</p>
                    <h2>总消耗能量</h2>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  </div>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			trainData: {
				index: 0,
				start: '',
				end: '',
			},
			sum_count: '',
			sum_total: '',
			sum_time: '',
			time: '',
			trainType: '0',
		};
	},
	methods: {
		firstDate() {
			let d = new Date();
			return `${d.getFullYear()}-${d.getMonth() + 1}-01`;
		},
		nowDate() {
			let d = new Date();
			let month = d.getMonth() + 1;
			month = month > 9 ? month : '0' + month;
			let day = d.getDate();
			day = day > 9 ? day : '0' + day;
			return `${d.getFullYear()}-${month}-${day}`;
		},
		last30Date() {
			// 获取当前日期
			var myDate = new Date();

			// 获取三十天前日期
			var lw = new Date(myDate - 1000 * 60 * 60 * 24 * 30);
			var lastY = lw.getFullYear();
			var lastM = lw.getMonth() + 1;
			var lastD = lw.getDate();
			var startdate =
				lastY + '-' + (lastM < 10 ? '0' + lastM : lastM) + '-' + (lastD < 10 ? '0' + lastD : lastD);

			return startdate;
		},
		handleInit() {
			this.trainData.member_id = this.$route.params.member_id;
			MemberService.zhaikeQuery(this.trainData).then((data) => {
				if (data.sum_count === null || data.sum_total === null || data.sum_time === null) {
					this.sum_time = 0;
					this.sum_total = 0;
					this.sum_count = 0;
				} else {
					this.sum_time = data.sum_time;
					this.sum_total = data.sum_total;
					this.sum_count = data.sum_count;
				}
			});
		},
		handleChangeDate(value) {
			this.trainData.start = value[0];
			this.trainData.end = value[1];
			this.handleInit();
		},
	},
	mounted() {
		this.time = [this.last30Date(), this.nowDate()];
		this.trainData.start = this.time[0];
		this.trainData.end = this.time[1];
		this.handleInit();
	},
};
</script>
<style lang="css" scoped>
.scheme-contain .data-contain {border: none;}
.sports-card {
  padding: 55px 25px;
  color: #fff;
  border-radius: 5px;
}
.train-search {
	display: flex;
	justify-content: space-between;
	margin-bottom: 35px;
}
</style>
